.topnav>.container {
	margin: 0 !important;
	text-align: center;
}

.topnav>.container>.actions>.gjs-pn-buttons {
	display: flex;
	flex-direction: column;
}

.topnav>.container>.panel__editor {
	background-color: transparent !important;
}

.topnav>.container>.panel__editor>.gjs-pn-buttons>span {
	background-color: transparent !important;
	margin: 5px;
	border: 2px solid #C9C9C9 !important;
	border-radius: 5px;
	font-size: 18px !important;
}

.topnav>.container>.panel__editor>.gjs-pn-buttons>.publish {
	background-color: #7983FD !important;
	color: white !important;
}

.topnav>.container>.panel__editor>.gjs-pn-buttons>span,
.topnav>.container>.panel__basic-actions>.gjs-pn-buttons>span {
	display: block;
}

.topnav>.container>.actions>button:nth-child(1) {
	border: 1px solid black;
	border-radius: 5px !important;
}