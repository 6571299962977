.report_list {
	height: 100%;
	width: 100%;
	position: absolute;
}

.report_list>.download-report-button {
	background-color: #7983FD;
	color: white !important;
	padding: 20px !important;
	border-radius: 5px !important;
	text-decoration: none !important;
}