html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: 'Poppins', sans-serif !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #edecec;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}

.admin {
  padding: 10px;
  display: flex;
}

.main-page-content {
  width: 100%;
  padding: 10px;
  padding-top: 0;
}

.pages {
  height: 85vh !important;
  overflow-y: scroll !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.filter-box {
  position: fixed;
  border: 1px solid black;
  padding: 10px;
  width: fit-content;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: 'Poppins';
  font-size: 20px;
  letter-spacing: 2px;
  cursor: pointer;
  backdrop-filter: blur(10px);
}

.btn {
  border-radius: 5px !important;
}