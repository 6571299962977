.project-view {
	height: 100%;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#project-body {
	color: gray;
	font-size: 14px;
}

Button {
	margin: 2px !important;
}

.description {
	font-size: 1.2rem;
	letter-spacing: 2px;
	color: black;
}