.sidebarWrapper {
	max-width: 300px;
	height: 100vh;
	background: #131d40;
	box-shadow: 0px 0px 50px #2a59981a;
	border-radius: 15px;
	padding: 25px 20px;
	transition: all 0.3s ease-in-out;
}

.topHolder>img {
	width: 80% !important;
}

.menus {
	margin-top: 35px;
}

.menuItem {
	color: #ffffff;
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	cursor: pointer;
	letter-spacing: 2px;
}

.menuItem>.icon {
	margin-right: 10px;
}