.features {
	margin: 10px;
	height: 80vh;
	width: 100%;
	font-family: '"Poppins", sans-serif';
	overflow-y: scroll;
	text-align: center;
	background-color: white;
	border-radius: 10px;
	margin: 10px;
	padding: 10px;
	box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	font-family: 'Poppins';
}

.features>.category>.features-list>div {
	width: 100%;
	text-align: start;
}

input[type="range"] {
	margin: 0;
	-webkit-appearance: none;
	position: relative;
	overflow: hidden;
	height: 5px;
	width: 100%;
	cursor: pointer;
	border-radius: 0;
	/* iOS */
}

::-webkit-slider-runnable-track {
	background: #ddd;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 * 2. Shadow is negative the full width of the input and has a spread 
 *    of the width of the input.
 */
::-webkit-slider-thumb {
	-webkit-appearance: none;
	background: #7980F5;
	width: 10px;
	height: 50px;
	border-radius: 50% !important;
	box-shadow: -200px 0 0 200px #7980F5;
	border: 2px solid transparent;
}

::-moz-range-track {
	height: 5px;
	background: #ddd;
}

::-moz-range-thumb {
	background: #7980F5;
	width: 10px;
	height: 50px;
	border-radius: 50%;
	box-shadow: -200px 0 0 200px #7980F5;
	border: 2px solid transparent;
	box-sizing: border-box;
}

::-ms-fill-lower {
	background: #7980F5;
}

::-ms-thumb {
	background: #7980F5;
	width: 10px;
	height: 25px;
	border-radius: 50%;
	box-shadow: -200px 0 0 200px #7980F5;
	border: 2px solid transparent;
	box-sizing: border-box;
}

::-ms-ticks-after {
	display: none;
}

::-ms-ticks-before {
	display: none;
}

::-ms-track {
	background: #ddd;
	color: transparent;
	height: 5px;
	border: none;
}

.category>h3 {
	color: black;
	padding: 10px;
	margin: 0;
	border: 1px solid lightgray;
	box-shadow: 0px 0px 16px -11px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 16px -11px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 16px -11px rgba(0, 0, 0, 0.75);
	font-size: 14px;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.category>.features-list>div {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
}

.category>.features-list>div>label {
	font-size: 15px;
	font-weight: bold;
	margin: 0 !important;
}

.category>.features-list>div {
	margin-bottom: 15px;
}

.actions {
	padding: 10px;
}

.actions>#saveModelButton {
	width: 100%;
	border-radius: 5px !important;
	background-color: #7980F5 !important;
}