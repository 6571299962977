@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Quattrocento);

.user_list {
	height: 100% !important;
	position: absolute;
}

.all-users {
	top: 0;
}

.snip {
	font-family: 'Poppins';
	position: relative;
	overflow: hidden;
	margin: 10px;
	min-width: 230px;
	max-width: 315px;
	width: 100%;
	color: #141414;
	text-align: left;
	line-height: 1.4em;
	font-size: 16px;
	border: 1px solid black;
}

.snip * {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.snip img {
	max-width: 100%;
	vertical-align: top;
}

.snip figcaption {
	width: 100%;
	background-color: #ffffff;
	padding: 15px 15px 30px;
	position: relative;
}

.snip figcaption:before {
	position: absolute;
	content: '';
	z-index: 2;
	bottom: 100%;
	left: 0;
	width: 100%;
	height: 50px;
	/* background-image: -webkit-linear-gradient(top, transparent 0%, #ffffff 100%);
	background-image: linear-gradient(to bottom, transparent 0%, #ffffff 100%); */
}

.snip h2,
.snip h6,
.snip p {
	margin: 0 0 10px;
	color: black;
	font-weight: 600;
}

.snip h2>span,
.snip h6>span,
.snip p>span {
	color: gray !important;
}

.snip h2 {
	font-weight: 300;
	font-size: 1.5em;
	font-family: 'Oswald', Arial, sans-serif;
	text-transform: uppercase;
	margin: 0 !important;
}

.snip p {
	font-size: 0.9em;
	letter-spacing: 1px;
	opacity: 0.9;
}

.snip .icons {
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: gray;
	width: 100%;
	text-align: center;
}

.user_list>.all-users>.download-report-button {
	background-color: #7983FD;
	color: white !important;
	padding: 20px !important;
	border-radius: 5px !important;
	text-decoration: none !important;
}