.topbarWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: white;
	font-family: 'Poppins', sans-serif !important;
	padding: 10px !important;
	background-color: black;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	/* height: 70px !important; */
}

.pageTitle {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ProfileActionSection {
	display: flex;
	align-items: center;
}

.pageTitle>h3 {
	color: white;
	font-weight: bold;
	margin-left: 10px;
	margin: 0;
}

.pageTitle>#logo {
	height: 30px;
	width: 30px;
}

.userInfo {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 10px;
}

.userInfo>img {
	border-radius: 50%;
}

.userInfo>h1 {
	margin-left: 10px;
	font-size: 1rem;
	cursor: pointer;
	font-weight: bold;
	margin: 0;
	margin-left: 5px;

}

.ProfileActionSection>svg {
	font-size: 1.5rem;
	color: white;
	margin-right: 10px;
	cursor: pointer;
}

.profileMenu {
	position: absolute !important;
	top: 60px;
	background: black;
	z-index: 999;
	padding: 10px 25px;
	box-shadow: 0px 1px 2px #00000024;
	border-radius: 15px;
}

.profileMenu>h3 {
	font-size: 16px;
	font-weight: normal;
	border-bottom: 0.2px solid #00000024;
	margin: 0;
	padding: 10px 0;
}