.view-user {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	border: 1px solid black;
}

.view-user .left-side {
	flex: 1.3;
	background-color: rgba(19, 28, 63, 0.4);
	border-right: 1px solid black;
}

.view-user .left-side img {
	width: 100%;
	height: 350px;
	padding: 10px;
	object-fit: cover;
}

.view-user .right-side {
	flex: 2;
	padding: 10px;
	border-left: 1px solid black;
	margin: 10px;
}

.view-user .right-side .input-box {
	display: flex;
	flex-direction: column;
}

.view-user .right-side .input-box div>p {
	margin: 0;
}

.view-user .right-side .input-box div {
	margin-bottom: 20px !important;
}

.view-user .right-side h5 {
	margin-bottom: 10px;
	margin-top: 0;
}

.view-user .right-side h2 {
	display: inline;
}

.file {
	opacity: 0;
	width: 0.1px;
	height: 0.1px;
	position: absolute;
}


.file-name {
	position: absolute;
	bottom: -35px;
	left: 10px;
	font-size: 0.85rem;
	color: #555;
}

.file-input label {
	display: block;
	position: relative;
	width: 100%;
	height: 50px;
	background: linear-gradient(40deg, #ff6ec4, #7873f5);
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	transition: transform .2s ease-out;
}

input:hover+label,
input:focus+label {
	transform: scale(1.02);
}

input:focus+label {
	outline: 1px solid #000;
	outline: -webkit-focus-ring-color auto 2px;
}


/* .view-user .left-side input[type="file"] {
	display: none;
}

.view-user .left-side .file-upload {
	border: none;
	background-color: brown;
	color: white;
	display: inline-block;
	text-align: center;
	padding: 10px;
	width: 100%;
	cursor: pointer;
	font-weight: bold;
} */