.nav {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10px;
	padding: 5px !important;
	border-radius: 10px !important;
	background-color: white;
	box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
}

.nav-tabs {
	background-color: white !important;
}

.nav>.nav-item {
	border: none !important;
	border-radius: 10px !important;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.nav>.nav-item>button>p {
	font-size: 14px;
}

.nav-link {
	color: black !important;
	margin: 0 !important;
	margin-bottom: 5px !important;
	border: none !important;
	background-color: transparent !important;
	padding: 2px !important;
}

.active {
	color: #7980F5 !important;
}

.fa {
	font-size: 20px !important;
}


.tab-content {
	color: black !important;
	font-weight: bold;
}