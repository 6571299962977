.filter {
	width: 25%;
	height: 80vh;
	border: 1px solid black;
	background-color: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(20px);
	position: absolute;
	position: fixed;
	top: 80px;
	padding: 10px;
	overflow-y: scroll;
	color: #131d40;
	/* left: 0; */
	/* bottom: 0; */
}

.actions {
	display: flex;
	align-items: center;
}