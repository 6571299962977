.plan_card {
	background-color: white;
	width: fit-content;
	text-align: center;
	cursor: pointer;
	padding: 5px;
	margin: 10px;
	transition: 0.5s ease-in-out;
}

.plan_card:hover {
	background-color: gray;
	background-image: linear-gradient(to bottom, transparent 0%, #fff 100%);

}

.plan_title {
	text-transform: uppercase;
	letter-spacing: 2px;
	border: 1px solid gray;
}

.plan_title>h5 {
	margin: 0;
	margin-top: 5px;
	margin-bottom: 5px;
}

.plan_details {
	background-color: white;
	border: 1px solid white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.plan_details>.title {
	text-transform: uppercase;
	font-weight: 700;
}

.plan_details>.plan_image {
	margin-top: 5px;
}

.plan_details>.plan_image>img {
	border: 0.3px solid lightgray;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	padding: 5px;
	background-color: gray;
	background-image: linear-gradient(to bottom, transparent 0%, #fff 100%);
}

.plan_details>.plan_features>ul {
	text-align: left;
	margin-top: 5px;
	margin-bottom: 5px;
}

.plan_details>.plan_features>ul>li {
	margin-bottom: 5px;
	font-size: 12px;
	color: gray;
	font-weight: bold;
}

.plan_details>.plan_features>ul>li>span {
	color: red;
	font-size: 14px;
}

.plan_details>.price {
	font-weight: 300;
}

.plan_details>.price>p {
	margin: 3px;
}

.plan_details>.price>h6 {
	margin-top: 0;
	margin-bottom: 0;
}

.plan_details>.price>p>span {
	font-size: 25px;
	font-weight: bold;
}