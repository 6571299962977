.model-editor {
	display: flex;
}

.sidebar {
	display: flex;
	flex: 0.5;
}

#model-viewer {
	width: 100%;
}

[class="3dmodel"] {
	height: 500px;
	width: 100%;
	/* background-color: red; */
	margin-bottom: 20px;
}

.editor-view {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	border: 1px solid black;
	padding: 10px;
	flex: 2;
}

.bs-button {
	border-radius: 5px !important;
	color: white !important;
	font-size: 14px;
	width: 100%;
	background: #7980F5
}