.authwrapper {
	/* min-height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
	padding: 10px;
}

.login-form-container {
	background: white;
	padding: 45px 30px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-form-container>h1 {
	font-size: 24px;
	text-align: center;
	margin: 0;
	font-weight: bold;
}

.login-form-container>p {
	font-size: 16px;
	text-align: center;
	font-weight: normal;
	color: #00000026;
}

.login-form-container>h4 {
	font-size: 16px;
	font-weight: normal;
}

.login-form {
	display: block;
	min-width: 450px;
	max-width: 90%;
	margin-top: 25px;
}

.login-buttons {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.formgroup {
	margin-top: 10px;
	position: relative;
}

label {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	font-weight: 500;
}

input {
	padding: 10px;
	width: 100%;
	border: 1px solid #ebebeb;
	border-radius: 5px;
	height: 50px;
	margin-bottom: 10px;
}

.or-divider {
	margin: 10px 0;
	display: flex;
	text-align: center;
	align-items: center;
	font-size: 1.25rem;
}

.or-divider>span {
	display: block;
	height: 1px;
	width: 40%;
	margin: auto;
	background: lightgrey;
	border-radius: 25px;
}

.social-button {
	background: #ffffff;
	border: 1px solid #ebebeb;
	padding: 10px 0;
	font-size: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	cursor: pointer;
}

.social-button>img {
	width: 25px;
	margin-right: 15px;
}

.social-button:hover {
	opacity: 0.8;
}