.authwrapper {
	/* min-height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
	padding: 10px;
	font-family: 'Poppins', sans-serif !important;
}

.register-form-container {
	width: 40%;
	background: white;
	padding: 15px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.register-form-container>h1 {
	font-size: 24px;
	text-align: center;
	margin: 0;
	font-weight: bold;
}

.register-form-container>h4 {
	text-align: center;
	font-size: 10px;
	font-weight: 100 !important;
	margin-top: 0;
}

.register-form {
	display: block;
	max-width: 100%;
	margin-top: 25px;
}

.register-buttons {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.register-formgroup {
	margin-top: 10px;
	position: relative;
}

.register-formgroup>label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 0.8rem;
	font-weight: 300;
}

.register-formgroup>input {
	width: 100%;
	border: 1px solid #ebebeb;
	border-radius: 5px;
}



input:focus:invalid~span {
	display: block;
}

.or-divider {
	margin: 10px 0;
	display: flex;
	text-align: center;
	align-items: center;
	font-size: 1rem;
}

.or-divider>span {
	display: block;
	height: 1px;
	width: 40%;
	margin: auto;
	background: lightgrey;
	border-radius: 25px;
}

.social-button {
	background: #ffffff;
	border: 1px solid #ebebeb;
	padding: 10px 0;
	font-size: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
}

.social-button>img {
	width: 25px;
	margin-right: 15px;
}

.social-button:hover {
	opacity: 0.8;
}

.eyeIcon {
	position: absolute;
	left: 90%;
	bottom: 16%;
	font-size: 20px;
	cursor: pointer;
}

.errorMessage {
	border-radius: 5px;
	border: none;
	color: red;
	font-size: 10px;
	display: none;
}